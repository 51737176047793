<template>
    <div class="footer">
      <van-row gutter="10" style="text-align: center;">
        <van-col span="8" @click="go">
            <img v-show="imgshow" class="img1" src="@/assets/img/xinxi-ok.png" alt="">
            <img v-show="!imgshow" class="img2" src="@/assets/img/xingi-no.png"><br>
            <div class="font" :class="{'fcolor': imgshow}">信息</div>
        </van-col>
        <van-col span="8" @click="to('/topics')">
            <img v-show="topicsShow" class="img1" src="@/assets/img/topics-ok.png" alt="">
            <img v-show="!topicsShow" class="img2" src="@/assets/img/topics-no.png"><br>
            <div class="font" :class="{'fcolor': topicsShow}">专题</div>
        </van-col>
        <van-col span="8" @click="to('/circle')">
            <img v-show="quanshow" class="img1" src="@/assets/img/quan-ok.png" alt="">
            <img v-show="!quanshow" class="img2" src="@/assets/img/quan-no.png"><br>
            <div class="font" :class="{'fcolor': quanshow}">班级圈</div>
        </van-col>
      </van-row>
    </div>

</template>

<script>
import {
    getUserInfo,
    setUserIndexPage,
    getUserIndexPage,
} from "@/utils/user.js";
import { isBind } from "@/api/login";
export default {
    data(){
        return{
            indexPage: [
                'teacher_index',
                'student_index',
            ],
            imgshow:true,
            // imgshow1:false,
            quanshow:false,
            // quanshow1:true,
            topicsShow:false,
        }
    },
    methods:{
        go(){
            let url = location.href
            let arr = url.split('/')
            let arr2 = arr.pop()
            if(arr2 == 'home' || arr2 == 'parents' || arr2 == 'noactva'){

            }else{
                const page = getUserIndexPage() || '';

                if (page) {
                    this.$router.push(`/${page}`);
                } else {
                    try {
                        const { openid }  = getUserInfo();
                        isBind({ 
                            openid: openid
                        });
                    } catch (e) {
                        this.$router.back();
                    }
                }
            }
            
        },  
        to(path){
            this.$router.push(path)
        },
    },
    created(){
        let url = location.href
        let arr = url.split('/')
        let arr2 = arr.pop()

        if (this.indexPage.includes(arr2)) 
            setUserIndexPage(arr2);

        // console.log(arr2);
        if(arr2 == 'home' || arr2 == 'parents' || arr2 == 'noactva'){
            this.imgshow = true
            // this.imgshow1 = false

            this.quanshow = false
            // this.quanshow1 = true

            this.topicsShow = false
        }else if(arr2 == 'circle'){
            this.imgshow = false
            // this.imgshow1 = true

            this.quanshow = true
            // this.quanshow1 = false
            this.topicsShow = false;
        }else if(arr2.indexOf('topics') != -1) {
            this.imgshow = false
            // this.imgshow1 = true

            this.quanshow = false
            // this.quanshow1 = true

            this.topicsShow = true;
        }
    }
};
</script>

<style lang="less" scoped>
img{
    width: 8vw;
}
.footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #F7F7F7;
    padding: .2rem 0 .2rem 0rem ;
    z-index: 10;
}
.icon{
    font-size: 1vw;
}
.font{
    font-size: 3vw;
    margin-top: -.3rem;
    color:#999999;
}
.fcolor{
    color: #668EFF;
}
</style>
