import request from '@/utils/request'

export function topicsList(query) {
    return request({
        url: '/mobile/study/index',
        method: 'get',
        params: query
    })
}

export function topicsDetail(query) {
    return request({
        url: '/mobile/study/detail',
        method: 'get',
        params: query
    })
}